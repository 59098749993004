export const orderHeaders = [
  {
    order: 1,
    label: 'Номер заказа',
    value: 'number',
  },
  {
    order: 2,
    label: 'Клиентский номер заказа',
    value: 'client_order_id',
  },
  {
    order: 3,
    label: 'Статус заказа',
    value: 'status',
  },
  {
    order: 4,
    label: 'Название',
    value: 'event_name',
  },
  {
    order: 5,
    label: 'Дата заказа',
    value: 'created_at',
    sortingValue: 'created_at',
  },
  {
    order: 6,
    label: 'ФИО',
    value: 'buyer_name',
  },
  {
    order: 7,
    label: 'ID пользователя',
    value: 'user_id',
  },
  {
    order: 8,
    label: 'Номер телефона',
    value: 'buyer_phone',
  },
  {
    order: 9,
    label: 'E-mail',
    value: 'buyer_email',
  },
  {
    order: 10,
    label: 'Город',
    value: 'city',
  },
  {
    order: 11,
    label: 'Дата мероприятия',
    value: 'seance__start_date',
    sortingValue: 'seance__start_date',
  },
  {
    order: 12,
    label: 'Сумма заказа',
    value: 'order_price',
  },
  {
    order: 13,
    label: 'Поставщик',
    value: 'provider',
  },
];

export const orderFilters = {
  ordering: '',
  number: '',
  client_order_id: '',
  q: '',
  email: '',
  phone: '',
  user_id: '',
  status: '',
  provider: '',
  city_id: [],
};

export const orderBlank = {
  id: '',
  user_id: '',
  number: '',
  status: '',
  buyer: { name: '', email: '', phone: '' },
  seance: {
    id: '',
    start_date: '',
    end_date: '',
    event_id: '',
    place_id: '',
    hall_name: '',
  },
  internal_status: '',
  created_at: '',
  updated_at: '',
  is_deleted: '',
  deleted_at: '',
  event_name: '',
  provider: '',
  city: '',
  pdf: '',
  order_price: 0,
  client_order_id: '',
  event_id: '',
};

export const orderStatusOptions = [
  { value: 'paid', label: 'Оплачен' },
  { value: 'refunded', label: 'Возвращен' },
  { value: 'partially_refunded', label: 'Частично возвращен' },
  { value: 'cancelled', label: 'Отменен' },
];
