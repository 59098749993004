import { Input } from '@vkontakte/vkui';
import React, { FC, useState, useEffect, ChangeEvent } from 'react';

interface NumberInputProps {
  value: number | null;
  onChange: (value: number | null) => void;
  placeholder?: string;
  name?: string;
  maxLength?: number;
  readOnly?: boolean;
}

export const NumberInput: FC<NumberInputProps> = ({
  value,
  onChange,
  placeholder,
  name,
  maxLength,
  readOnly,
}) => {
  const [inputValue, setInputValue] = useState<string>(value?.toString() || '');

  useEffect(() => {
    setInputValue(value?.toString() || '');
  }, [value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    if (
      /^\d*$/.test(newValue) &&
      (!maxLength || newValue.length <= maxLength)
    ) {
      setInputValue(newValue);

      const numericValue = newValue === '' ? null : parseInt(newValue, 10);
      onChange(numericValue);
    }
  };

  return (
    <Input
      type="text"
      value={inputValue}
      onChange={handleChange}
      placeholder={placeholder}
      name={name}
      maxLength={maxLength}
      readOnly={readOnly}
    />
  );
};
