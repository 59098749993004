import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Div,
  Group,
  ModalRoot,
  Title,
  FormItem,
  Panel,
  PanelHeader,
  Tabs,
  TabsItem,
  View,
  Spinner,
  ModalPage,
  Header,
  Image,
  Placeholder,
  File,
  Select,
  ModalCard,
  Text,
  Checkbox,
  Link,
  ChipsInput,
} from '@vkontakte/vkui';
import 'react-image-crop/dist/ReactCrop.css';
import ReactQuill from 'react-quill';
import {
  Icon24Attachments,
  Icon20DeleteOutline,
  Icon20AddCircle,
  Icon24ExternalLinkOutline,
  Icon20CropOutline,
  Icon20PicturePlusOutline,
  Icon20PictureOnSquareOutline,
} from '@vkontakte/icons';
import { textEditorFormats, textEditorModules } from 'lib/utils/textEditor';
import ImageCropper from 'components/common/ImageCropper';
import ImageGallery from 'components/common/ImageGallery';
import { ADMIN_EVENTS_ROUTE, ASPECT_RATIO, MIN_DIMENSION } from 'lib/constants';
import {
  handleFileSelection,
  handleImageChange,
  handleImageUrl,
  handleOrderInput,
} from 'lib/utils/imageFile.utils';
import { arraysAreEqual } from 'lib/utils/arrayСomparison';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import { findObjectsDiffs, isObjectsEqual } from 'lib/utils/findObjectsDiffs';
import { TextTooltip } from '@vkontakte/vkui/dist/components/TextTooltip/TextTooltip';
import { NumberInput } from 'components/common/NumberInput';
import { getClearUrl } from 'lib/utils/getClearUrl';
import { replaceEmptyWithNull } from 'lib/utils/replaceEmptyWithNull';
import { IChip } from 'lib/types';
import { getUserRole } from 'lib/utils/authUtils';
import { placeBlank } from './constants.places';
import { IPlaceEdit, IPlacePhone } from './types.places';
import useFetch from 'lib/hooks/useFetch';
import useFormItems from 'lib/hooks/useFormItems';
import EventFormItem from 'components/common/EventFormItem';
import PanelTitle from 'components/common/PanelTitle';

const PlacesEdit: FC = () => {
  const userRole = getUserRole();
  const { data, loading, fetchData } = useFetch<IPlaceEdit>();
  const { fetchData: patchData } = useFetch();
  const [placeData, setPlaceData] = useState<IPlaceEdit>(placeBlank);
  const { formData, isError, errorItems, handleChange } =
    useFormItems<IPlaceEdit>({
      initialValues: placeData,
    });
  const {
    name,
    email,
    city,
    weight,
    vk_url,
    work_time,
    address,
    geo_lat,
    geo_lon,
    slug,
    source_owner,
    changed_from_admin,
  } = formData;
  const { placeId, methodType } = useParams();
  const { setSnackbarContent } = useSnackbar();
  const [openModal, setOpenModal] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<string>(() => {
    return sessionStorage.getItem('selectedPlaceTab') || 'info';
  });
  const [newPhones, setNewPhones] = useState<IPlacePhone[]>([]);
  const [newDescription, setNewDescription] = useState<string>('');
  const [hasNewDescription, setHasNewDescription] = useState<boolean>(false);
  const [newInfo, setNewInfo] = useState<string>('');
  const [hasNewInfo, setHasNewInfo] = useState<boolean>(false);
  const [isNewEnabled, setIsNewEnabled] = useState<boolean>(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [imageSrc, setImageSrc] = useState<string>('');
  const [newCover, setNewCover] = useState<string>('');
  const [newCoverOriginal, setNewCoverOriginal] = useState<string>('');
  const [cropError, setCropError] = useState<string>('');
  const [displayedImageIndex, setDisplayedImageIndex] = useState<number>(0);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [mapImageUrl, setMapImageUrl] = useState<string>('');
  const [newVkId, setNewVkId] = useState<number | null>(null);
  const [newDataEnricher, setNewDataEnricher] = useState<IChip[]>([]);

  const fetchDataFunction = () => {
    fetchData({ path: `/search/places/${placeId}`, method: 'get' });
  };

  const handleChangePhone = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { value } = event.target;
    const updatedPhones = [...newPhones];
    updatedPhones[index] = { ...updatedPhones[index], phone: value };
    setNewPhones(updatedPhones);
  };

  const handleVkIdChange = (value: number | null) => {
    setNewVkId(value);
  };

  const handleAddPhone = () => {
    const newAllPhones = [
      ...newPhones,
      {
        type: 'string',
        phone: '',
      },
    ];

    setNewPhones(newAllPhones);
  };

  const handleDeletePhone = (phoneId: number) => {
    const updatedPhones = newPhones.filter((phone, index) => index !== phoneId);

    setNewPhones(updatedPhones);
  };

  const handleIsNewEnabledChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsNewEnabled(event.target.checked);
  };

  const handleSave = async () => {
    const diffObject = findObjectsDiffs(formData, placeData);
    const diffKeys = Object.keys(diffObject);
    // eslint-disable-next-line
    const diffData: Record<string, any> = {};

    if (diffKeys.length !== 0) {
      diffKeys.forEach((key) => {
        diffData[key] = formData[key as keyof IPlaceEdit];
      });
    }

    if (!isObjectsEqual(placeData?.phone, newPhones))
      diffData.phone = newPhones;

    if (hasNewDescription && placeData.description !== newDescription)
      diffData.description = newDescription;

    if (hasNewInfo && placeData.info !== newInfo) diffData.info = newInfo;

    if (placeData?.status !== isNewEnabled) diffData.status = isNewEnabled;

    if (placeData?.vk_id !== newVkId) {
      newVkId === null
        ? (diffData.vk_id = null)
        : (diffData.vk_id = Number(newVkId));
    }

    if (Object.keys(diffData).length > 0) {
      const editPromise = patchData({
        path: `/venue/places/${placeId}/`,
        method: 'patch',
        body: replaceEmptyWithNull(diffData),
        onSuccessMessage: 'Изменения сохранены',
      });

      const response = await editPromise;
      const error = response?.errorData?.response?.data;

      if (error?.code) {
        setSnackbarContent({
          type: 'error',
          message: error.error_message,
        });
      } else {
        await fetchDataFunction();
      }
    }
  };

  const onCoverSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;

    handleFileSelection({
      minDimension: MIN_DIMENSION,
      aspectRatio: ASPECT_RATIO,
      file: file,
      setCropError: setCropError,
      setImageSrc: setImageSrc,
      setOpenModal: setOpenModal,
      setSnackbarContent: setSnackbarContent,
      setOriginal: setNewCoverOriginal,
    });
  };

  const handleImageDelete = (indexToRemove: number) => {
    const newSelectedImages = selectedImages.filter(
      (_, index) => index !== indexToRemove,
    );
    setSelectedImages(newSelectedImages);
  };

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    sessionStorage.setItem('selectedPlaceTab', selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab) {
      setSelectedTab(tab);
    }
  }, [location.search]);

  useEffect(() => {
    fetchDataFunction();
  }, []);

  useEffect(() => {
    if (methodType === 'edit') {
      if (!loading && data) {
        setPlaceData(data);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (placeData.picture_original) {
      handleImageUrl(
        MIN_DIMENSION,
        ASPECT_RATIO,
        placeData.picture_original,
        setCropError,
        setImageSrc,
      );
    }

    setNewDescription(placeData.description);
    setNewInfo(placeData.info);
    setNewCover(placeData.picture);
    setNewCoverOriginal(placeData.picture_original);
    setNewVkId(placeData.vk_id);

    placeData.status && setIsNewEnabled(placeData.status);

    placeData?.phone !== null
      ? setNewPhones(placeData.phone)
      : setNewPhones([]);

    setSelectedImages([]);
    setSelectedImages((prevImages) => {
      if (Array.isArray(placeData.image_urls)) {
        return [...prevImages, ...placeData.image_urls];
      } else if (typeof placeData.image_urls === 'string') {
        return [...prevImages, placeData.image_urls];
      } else {
        return prevImages;
      }
    });

    if (placeData.data_enricher) {
      setNewDataEnricher(
        placeData.data_enricher.map((e) => {
          return { label: e, value: e };
        }),
      );
    }

    setMapImageUrl(
      `https://maps.vk.com/api/staticmap/png?api_key=${process.env.REACT_APP_VK_MAPS_KEY}` +
        `&latlon=${placeData.geo_lat},${placeData.geo_lon}` +
        '&zoom=13' +
        `&width=1088` +
        '&height=200' +
        '&scale=2' +
        `&pins=${placeData.geo_lat},${placeData.geo_lon},mail-electric_target`,
    );
  }, [placeData]);

  useEffect(() => {
    const updateCover = async () => {
      if (placeData.picture !== newCover) {
        const diffData: Record<string, string> = {};
        diffData.picture = newCover;

        if (
          newCoverOriginal !== '' &&
          placeData.picture_original !== newCoverOriginal
        ) {
          diffData.picture_original = newCoverOriginal;
        }

        if (
          newCoverOriginal === '' &&
          placeData.picture_original !== newCoverOriginal
        ) {
          diffData.picture_original = '';
        }

        try {
          await patchData({
            path: `/venue/places/${placeId}/`,
            method: 'patch',
            body: diffData,
            onSuccessMessage: 'Обложка обновлена',
          });

          navigate(0);
        } catch (error) {
          setSnackbarContent({
            type: 'error',
            message: 'Ошибка при обновлении обложки',
          });
        }
      }
    };

    updateCover();
  }, [newCover]);

  useEffect(() => {
    if (
      !arraysAreEqual(
        Array.isArray(placeData.image_urls)
          ? placeData.image_urls
          : [placeData.image_urls],
        selectedImages,
      )
    ) {
      const editedData = { image_urls: selectedImages };

      patchData({
        path: `/venue/places/${placeId}/`,
        method: 'patch',
        body: editedData,
        onSuccessMessage: 'Изображения обновлены',
      });
    }
  }, [selectedImages]);

  useEffect(() => {
    newInfo === '<p><br></p>' && setNewInfo('');
    newDescription === '<p><br></p>' && setNewDescription('');
  }, [newInfo, newDescription]);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <>
          <PanelTitle arrowColor="#99A2AD">
            <Title className="text-color-black">
              {methodType === 'edit'
                ? `Детали места проведения`
                : 'Добавить место проведения'}
            </Title>
            {methodType === 'edit' && (
              <Title className="inline text-color-steel-gray-500">
                {placeData.name}
              </Title>
            )}
          </PanelTitle>
          <View activePanel="panel" className="vkui-tabs">
            <Panel id="panel">
              <PanelHeader>
                <Tabs layoutFillMode={'shrinked'} className="flex pl-5">
                  <TabsItem
                    selected={selectedTab === 'info'}
                    onClick={() => {
                      handleTabChange('info');
                    }}
                    id="tab-info"
                    aria-controls="tab-content-info"
                  >
                    Информация
                  </TabsItem>
                  <TabsItem
                    selected={selectedTab === 'location'}
                    onClick={() => {
                      handleTabChange('location');
                    }}
                    id="tab-location"
                    aria-controls="tab-content-location"
                  >
                    Местоположение
                  </TabsItem>
                  <TabsItem
                    selected={selectedTab === 'media'}
                    onClick={() => {
                      handleTabChange('media');
                    }}
                    id="tab-media"
                    aria-controls="tab-content-media"
                  >
                    Медиа
                  </TabsItem>
                </Tabs>
              </PanelHeader>
              {selectedTab === 'info' && (
                <Group
                  id="tab-content-info"
                  aria-labelledby="tab-info"
                  role="tabpanel"
                  aria-controls="tab-content-info"
                >
                  <FormItem top="Ссылка на мероприятие (ЧПУ)">
                    {!slug || !placeData.place_type_ru ? (
                      <div>Ссылка не сформирована</div>
                    ) : process.env.REACT_APP_ENV === 'develop' ? (
                      <div className="flex flex-col">
                        <span className="text-sm">{slug}</span>
                        <span className="text-xs text-gray-500">
                          (В текущем окружении нет ссылки на WEB)
                        </span>
                      </div>
                    ) : (
                      <Link
                        href={getClearUrl(
                          'place',
                          placeData.place_type_ru,
                          slug,
                        )}
                        target="_blank"
                      >
                        {slug}
                        <Icon24ExternalLinkOutline
                          width={18}
                          height={18}
                          className="mb-1"
                        />
                      </Link>
                    )}
                  </FormItem>
                  <EventFormItem
                    className="vkui-input"
                    top="Название"
                    name="name"
                    value={name || ''}
                    onChange={handleChange}
                    placeholder="Укажите название"
                    isRequired={true}
                    isError={isError}
                    errorItems={errorItems}
                    readOnly={userRole === 'support'}
                  />
                  <EventFormItem
                    className="vkui-input"
                    top="Владелец данных"
                    name="source_owner"
                    value={source_owner || ''}
                    onChange={handleChange}
                    placeholder="Владелец не указан"
                    isRequired={true}
                    isError={isError}
                    errorItems={errorItems}
                    readOnly
                  />
                  <FormItem htmlFor="data_enricher" top="Обогащение данных">
                    <ChipsInput
                      id="data_enricher"
                      placeholder="Источники не указаны"
                      value={newDataEnricher || []}
                      disabled
                    />
                  </FormItem>
                  <EventFormItem
                    className="vkui-input"
                    top="Email"
                    name="email"
                    value={email || ''}
                    onChange={handleChange}
                    placeholder="Укажите email"
                    isRequired={false}
                    isError={isError}
                    errorItems={errorItems}
                    readOnly={userRole === 'support'}
                  />
                  <>
                    {newPhones.length > 0 &&
                      newPhones.map(({ phone }, index) => (
                        <div
                          key={index}
                          className="group flex items-center justify-between w-72"
                        >
                          <EventFormItem
                            className="vkui-input w-72"
                            top={`Телефон ${index + 1}`}
                            name={`phone-${index + 1}`}
                            value={phone || ''}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                              handleChangePhone(event, index)
                            }
                            placeholder="Укажите номер телефона"
                            isRequired={false}
                            isError={isError}
                            errorItems={errorItems}
                            readOnly={userRole === 'support'}
                          />
                          {userRole !== 'support' && (
                            <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              <TextTooltip
                                text="Удалить телефон"
                                arrowPadding={0}
                                placement="right"
                              >
                                <Icon20DeleteOutline
                                  width={18}
                                  color={'#99A2AD'}
                                  className="cursor-pointer mt-7"
                                  onClick={() => handleDeletePhone(index)}
                                />
                              </TextTooltip>
                            </div>
                          )}
                        </div>
                      ))}
                    {newPhones.length < 5 && userRole !== 'support' && (
                      <Div>
                        <Button
                          mode="secondary"
                          size="m"
                          appearance="accent"
                          onClick={handleAddPhone}
                          before={
                            <Icon20AddCircle width={18} className="ml-0.5" />
                          }
                        >
                          Добавить телефон
                        </Button>
                      </Div>
                    )}
                  </>
                  <EventFormItem
                    className="vkui-input"
                    top="Город"
                    name="city"
                    value={city || ''}
                    onChange={handleChange}
                    placeholder="Укажите город"
                    isRequired={true}
                    isError={isError}
                    errorItems={errorItems}
                    readOnly
                  />
                  <EventFormItem
                    className="vkui-input"
                    top="Часы работы"
                    name="work_time"
                    value={work_time || ''}
                    onChange={handleChange}
                    placeholder="Укажите часы работы"
                    isRequired={true}
                    isError={isError}
                    errorItems={errorItems}
                    readOnly={userRole === 'support'}
                  />
                  <EventFormItem
                    className="vkui-input"
                    top="Ссылка на сообщество ВК"
                    name="vk_url"
                    value={vk_url || ''}
                    onChange={handleChange}
                    placeholder="Укажите ссылку"
                    isRequired={false}
                    isError={isError}
                    errorItems={errorItems}
                    readOnly={userRole === 'support'}
                  />
                  <FormItem
                    className="vkui-input"
                    top="ID ссылки на сообщество ВК"
                  >
                    <NumberInput
                      value={newVkId}
                      onChange={handleVkIdChange}
                      name="vk_id"
                      placeholder="Укажите ссылку"
                      maxLength={15}
                      readOnly={userRole === 'support'}
                    />
                  </FormItem>
                  <FormItem top="Вес места проведения">
                    <Select
                      name="weight"
                      className="vkui-select"
                      placeholder="Укажите вес"
                      value={String(weight)}
                      onChange={handleChange}
                      options={[
                        { label: '1', value: 1 },
                        { label: '2', value: 2 },
                        { label: '3', value: 3 },
                      ]}
                      disabled={userRole === 'support'}
                    />
                  </FormItem>
                  <FormItem top="Описание">
                    <ReactQuill
                      className="text-editor"
                      value={newDescription}
                      onChange={(value) => setNewDescription(value)}
                      onChangeSelection={() => setHasNewDescription(true)}
                      modules={textEditorModules}
                      formats={textEditorFormats}
                      readOnly={userRole === 'support'}
                    />
                  </FormItem>
                  <FormItem top="Информация к посещению">
                    <ReactQuill
                      className="text-editor"
                      value={newInfo}
                      onChange={(value) => setNewInfo(value)}
                      onChangeSelection={() => setHasNewInfo(true)}
                      modules={textEditorModules}
                      formats={textEditorFormats}
                      readOnly={userRole === 'support'}
                    />
                  </FormItem>
                  <EventFormItem
                    className="vkui-input"
                    top="Изменено админом"
                    name="changed_from_admin"
                    value={changed_from_admin ? 'Да' : 'Нет'}
                    onChange={handleChange}
                    placeholder="Изменено админом"
                    isRequired={true}
                    isError={isError}
                    errorItems={errorItems}
                    readOnly
                  />
                  <FormItem className="py-0 mt-3 mb-2 ml-3 w-32">
                    <Checkbox
                      checked={isNewEnabled}
                      onChange={handleIsNewEnabledChange}
                      disabled={userRole === 'support'}
                    >
                      Активно
                    </Checkbox>
                  </FormItem>
                  <Div>
                    <Button
                      mode="secondary"
                      href={`${ADMIN_EVENTS_ROUTE}?place_id=["${placeData.id}"]`}
                      target="_blank"
                      size="l"
                      appearance="accent"
                    >
                      Посмотреть мероприятия
                    </Button>
                  </Div>
                  {userRole !== 'support' && (
                    <Div>
                      <ButtonGroup align="right" stretched>
                        <Button
                          className="vkui-edit-button-primary"
                          mode="primary"
                          size="l"
                          appearance="accent"
                          onClick={handleSave}
                        >
                          Сохранить
                        </Button>
                      </ButtonGroup>
                    </Div>
                  )}
                </Group>
              )}
              {selectedTab === 'location' && (
                <Group
                  id="tab-content-location"
                  aria-labelledby="tab-location"
                  role="tabpanel"
                  aria-controls="tab-content-location"
                >
                  <EventFormItem
                    className="vkui-input"
                    top="Адрес"
                    name="address"
                    value={address || ''}
                    onChange={() => false}
                    placeholder=""
                    isRequired={false}
                    isError={isError}
                    errorItems={errorItems}
                    readOnly
                  />
                  <EventFormItem
                    className="vkui-input"
                    top="Ближайшие станции метро"
                    name="undergrounds"
                    value={
                      placeData?.undergrounds
                        ?.map((underground) => underground.branch_name)
                        .join(', ') || 'Станции метро не найдены'
                    }
                    onChange={() => false}
                    placeholder={''}
                    isRequired={false}
                    isError={isError}
                    errorItems={errorItems}
                    readOnly
                  />
                  <EventFormItem
                    className="vkui-input"
                    top="Координаты"
                    name="coordinates"
                    value={`${geo_lat}, ${geo_lon}`}
                    onChange={() => false}
                    placeholder=""
                    isRequired={false}
                    isError={isError}
                    errorItems={errorItems}
                    readOnly
                  />
                  <Div className="relative">
                    <Spinner
                      size="large"
                      className="spinner absolute left-0 top-0"
                    />
                    <img
                      src={mapImageUrl}
                      className="w-full rounded-[8px] h-[300px] object-cover relative"
                    ></img>
                  </Div>
                </Group>
              )}
              {selectedTab === 'media' && (
                <Group
                  id="tab-content-media"
                  aria-labelledby="tab-media"
                  role="tabpanel"
                  aria-controls="tab-content-media"
                >
                  <Div className="pb-8">
                    <Div className="flex justify-between gap-2 m-0 py-0 px-2">
                      <Header mode="primary">Обложка</Header>
                      {userRole !== 'support' && (
                        <div className="flex flex-row">
                          {imageSrc && (
                            <FormItem className="-mr-4">
                              <Button
                                className="bg-color-blue-button"
                                before={
                                  <Icon20CropOutline
                                    width={18}
                                    className="ml-0.5"
                                  />
                                }
                                mode="secondary"
                                size="l"
                                appearance="accent"
                                accept="image/*"
                                onClick={() => setOpenModal('crop')}
                              >
                                Изменить обложку
                              </Button>
                            </FormItem>
                          )}
                          <FormItem>
                            <File
                              before={
                                <Icon20PicturePlusOutline
                                  width={18}
                                  className="ml-0.5"
                                />
                              }
                              mode="secondary"
                              size="l"
                              appearance="accent"
                              accept="image/*"
                              onChange={onCoverSelect}
                            >
                              Новая обложка
                            </File>
                          </FormItem>
                        </div>
                      )}
                    </Div>
                    {newCover ? (
                      <Div className="flex gap-2 px-5 m-0 py-0">
                        <Image
                          className="group m-0 p-0"
                          size={96}
                          src={newCover}
                          onClick={() => setOpenModal('coverPreview')}
                        >
                          {userRole !== 'support' && (
                            <Div className="m-2 p-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              <Image.Badge
                                background={'stroke'}
                                className="bg-white"
                              >
                                <Icon20DeleteOutline
                                        color={'#99A2AD'}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setOpenModal('deleteCover');
                                  }}
                                  className="cursor-pointer"
                                />
                              </Image.Badge>
                            </Div>
                          )}
                        </Image>
                      </Div>
                    ) : (
                      <Div>
                        <Placeholder
                          className="m-0 p-0"
                          icon={<Icon24Attachments />}
                          header={'Обложка отсутствует'}
                        >
                          Выберите обложку
                        </Placeholder>
                      </Div>
                    )}
                    <Div className="flex justify-between gap-2 m-0 mt-4 py-0 px-2">
                      <Header mode="primary">Изображения</Header>
                      {userRole !== 'support' && (
                        <FormItem className="mb-4">
                          <File
                            before={
                              <Icon20PictureOnSquareOutline
                                width={20}
                                className="ml-0.5"
                              />
                            }
                            mode="secondary"
                            size="l"
                            appearance="accent"
                            onChange={(e) =>
                              handleImageChange(e, {
                                setSelectedImages,
                                setSnackbarContent,
                              })
                            }
                            accept="image/*"
                          >
                            Добавить изображение
                          </File>
                        </FormItem>
                      )}
                    </Div>
                    {selectedImages.length > 0 ? (
                      <Div className="flex gap-2 px-5 m-0 py-0 flex-wrap">
                        {selectedImages.map((img, index) => {
                          return (
                            <Div
                              key={index}
                              className="flex flex-col items-center m-0 p-0"
                            >
                              <Image
                                className="group m-0 p-0"
                                size={96}
                                src={img}
                                onClick={() => {
                                  setOpenModal('imageGallery');
                                  setDisplayedImageIndex(index);
                                }}
                              >
                                {userRole !== 'support' && (
                                  <Div className="m-2 p-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                    <Image.Badge
                                      background={'stroke'}
                                      className="bg-white"
                                    >
                                      <Icon20DeleteOutline
                                        color={'#99A2AD'}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setOpenModal('deleteMedia');
                                          setDeleteIndex(index);
                                        }}
                                        className="cursor-pointer"
                                      />
                                    </Image.Badge>
                                  </Div>
                                )}
                              </Image>
                              <input
                                type="text"
                                disabled={userRole === 'support'}
                                onInput={(event) =>
                                  handleOrderInput(event, index, {
                                    selectedImages,
                                    setSelectedImages,
                                  })
                                }
                                className="focus:outline-none focus:ring-2 focus:ring-color-nav-items-blue w-8 max-h-8 border-1 text-center rounded-md mt-2 mb-2 py-px bg-color-card-grey border-none text-color-steel-gray-500"
                                value={index + 1}
                              />
                            </Div>
                          );
                        })}
                      </Div>
                    ) : (
                      <Div>
                        <Placeholder
                          className="m-0 p-0"
                          icon={<Icon24Attachments />}
                          header={'Медиафайлы не найдены'}
                        >
                          Добавьте изображение
                        </Placeholder>
                      </Div>
                    )}
                  </Div>
                </Group>
              )}
            </Panel>
          </View>
        </>
      )}
      <ModalRoot activeModal={openModal}>
        <ModalPage
          className="fixed"
          id="crop"
          onClose={() => setOpenModal('')}
          settlingHeight={100}
          height={'70%'}
          size={'l'}
        >
          {cropError && <p className="text-red-400">{cropError}</p>}
          <ImageCropper
            minDimension={MIN_DIMENSION}
            aspectRatio={ASPECT_RATIO}
            imageSrc={imageSrc}
            setOpenModal={setOpenModal}
            setState={setNewCover}
          />
        </ModalPage>
        <ModalPage
          id="coverPreview"
          onClose={() => setOpenModal('')}
          settlingHeight={100}
          className="flex flex-col items-center justify-center fixed max-h-fit"
          size={1200}
          dynamicContentHeight={true}
        >
          <Div className="flex flex-col items-center justify-center relative max-h-fit">
            <img
              src={newCover}
              className="p-4 rounded-3xl object-contain max-h-[640px]"
            />
          </Div>
        </ModalPage>
        <ModalPage
          className="fixed"
          id="imageGallery"
          onClose={() => setOpenModal('')}
          settlingHeight={100}
          height={'70%'}
          size={'l'}
        >
          <ImageGallery
            selectedImages={selectedImages}
            displayedImageIndex={displayedImageIndex}
            setDisplayedImageIndex={setDisplayedImageIndex}
          />
        </ModalPage>
        <ModalCard
          className="fixed"
          id="deleteCover"
          onClose={() => setOpenModal('')}
        >
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black mb-2" level="2">
              Удаление обложки
            </Title>
            <Text className="text-color-gray-600 mb-6">
              Вы действительно хотите удалить обложку?
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Назад
              </Button>
              <Button
                mode="primary"
                className="vkui-edit-button-error"
                size="m"
                onClick={() => {
                  setNewCover('');
                  setNewCoverOriginal('');
                  setOpenModal('');
                }}
              >
                Удалить
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
        <ModalCard
          className="fixed"
          id="deleteMedia"
          onClose={() => {
            setOpenModal('');
            setDeleteIndex(null);
          }}
        >
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black mb-2" level="2">
              Удаление изображения
            </Title>
            <Text className="text-color-gray-600 mb-6">
              Вы действительно хотите удалить изображение?
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => {
                  setOpenModal('');
                  setDeleteIndex(null);
                }}
              >
                Назад
              </Button>
              <Button
                mode="primary"
                className="vkui-edit-button-error"
                size="m"
                onClick={() => {
                  if (deleteIndex !== null) {
                    handleImageDelete(deleteIndex);
                    setOpenModal('');
                    setDeleteIndex(null);
                  }
                }}
              >
                Удалить
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
      </ModalRoot>
    </>
  );
};

export default PlacesEdit;
