import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Div,
  FormItem,
  Group,
  ModalCard,
  ModalRoot,
  Spinner,
  Text,
  Title,
} from '@vkontakte/vkui';
import { ChipsSelect } from '@vkontakte/vkui/dist/components/ChipsSelect/ChipsSelect';
import { ApiResponse } from 'lib/api/api';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import { findObjectsDiffs } from 'lib/utils/findObjectsDiffs';
import { arraysAreEqual } from 'lib/utils/arrayСomparison';
import { replaceEmptyWithNull } from 'lib/utils/replaceEmptyWithNull';
import { ADMIN_DIRECTORIES_ROUTE } from 'lib/constants';
import useFetch from 'lib/hooks/useFetch';
import useFormItems from 'lib/hooks/useFormItems';
import { genreBlank } from './constants.genresDirectory';
import { IGenre } from './types.genresDirectory';
import { IType } from '../TypesDirectory/types.typesDirectory';
import { IChip } from 'lib/types';
import PanelTitle from 'components/common/PanelTitle';
import EventFormItem from 'components/common/EventFormItem';

const GenresDirectoryEdit = () => {
  const { data, loading, fetchData, error } = useFetch<IGenre>();
  const { fetchData: patchData } = useFetch();
  const {
    data: categoryOptionsData,
    loading: categoryOptionsLoading,
    fetchData: categoryOptionsFetch,
  } = useFetch<ApiResponse<IType[]>>();
  const { directoryId, methodType } = useParams();
  const navigate = useNavigate();
  const { setSnackbarContent } = useSnackbar();
  const [openModal, setOpenModal] = useState<string>('');
  const [allCategories, setAllCategories] = useState<IChip[]>([]);
  const [newCategories, setNewCategories] = useState<IChip[]>([]);
  const [isNewActive, setIsNewActive] = useState<boolean>(false);
  const [genreData, setGenreData] = useState<IGenre>(genreBlank);
  const { formData, isError, errorItems, handleChange } = useFormItems<IGenre>({
    initialValues: genreData,
  });
  const { name } = formData;

  const handleIsNewActive = (event: ChangeEvent<HTMLInputElement>) => {
    setIsNewActive(event.target.checked);
  };

  const handleDeleteGenre = () => {
    fetchData({
      path: `/event/genres/${directoryId}/`,
      method: 'delete',
      onSuccessMessage: 'Успешно удалено',
    })
      .then(() => setOpenModal(''))
      .then(() => navigate(`${ADMIN_DIRECTORIES_ROUTE}/genres`));
  };

  const handleSave = async () => {
    if (methodType === 'addNew') {
      // eslint-disable-next-line
      const { id, ...diffData } = formData;
      diffData.is_active = isNewActive;
      diffData.short_name = name;

      const addPromise = patchData({
        path: `/event/genres/`,
        method: 'post',
        body: {
          ...diffData,
          categories: newCategories.map((obj) => obj.value),
        },
        onSuccessMessage: 'Успешно добавлено',
      });

      const response = await addPromise;
      const error = response?.errorData?.response?.data?.detail;

      if (error?.code) {
        setSnackbarContent({
          type: 'error',
          message: error.error_message,
        });
      } else {
        await navigate(`${ADMIN_DIRECTORIES_ROUTE}/genres`);
      }
    }

    if (methodType === 'edit') {
      const diffObject = findObjectsDiffs(formData, genreData);
      const diffKeys = Object.keys(diffObject);
      // eslint-disable-next-line
      const diffData: Record<string, any> = {};

      if (diffKeys.length !== 0) {
        diffKeys.forEach((key) => {
          diffData[key] = formData[key as keyof IGenre];
        });
      }

      if (genreData?.is_active !== isNewActive)
        diffData.is_active = isNewActive;

      if (
        !arraysAreEqual(
          genreData?.categories.map((category) => category.id),
          newCategories.map((category) => category.value),
        )
      ) {
        diffData.categories = newCategories.map((category) => category.value);
      }

      if (Object.keys(diffData).length > 0) {
        const editPromise = patchData({
          path: `/event/genres/${directoryId}/`,
          method: 'patch',
          body: replaceEmptyWithNull(diffData),
          onSuccessMessage: 'Изменения сохранены',
        });

        const response = await editPromise;
        const error = response?.errorData?.response?.data?.detail;

        if (error?.code) {
          setSnackbarContent({
            type: 'error',
            message: error.error_message,
          });
        } else {
          await fetchDataFunction();
        }
      }
    }
  };

  const fetchDataFunction = () => {
    if (methodType === 'edit') {
      fetchData({ path: `/event/genres/${directoryId}/`, method: 'get' });
    }
  };

  useEffect(() => {
    if (data && !loading) {
      setGenreData(data);
      setNewCategories(
        data.categories.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      );
      setIsNewActive(data.is_active);
    }
  }, [loading]);

  useEffect(() => {
    if (!categoryOptionsLoading && categoryOptionsData?.results) {
      setAllCategories(
        categoryOptionsData.results.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      );
    }
  }, [categoryOptionsLoading]);

  useEffect(() => {
    fetchDataFunction();

    categoryOptionsFetch({
      path: `/event/categories/?is_active=true`,
      method: 'get',
    });
  }, []);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <>
          <PanelTitle>
            <Title className="text-color-black">
              {methodType === 'edit' ? `Жанр` : 'Добавить жанр'}
            </Title>
            {methodType === 'edit' && (
              <Title className="inline text-color-steel-gray-500">
                {genreData?.name}
              </Title>
            )}
          </PanelTitle>
          <Group className="custom-scrollbar">
            <EventFormItem
              className="vkui-input"
              top="Название"
              name="name"
              value={name}
              onChange={handleChange}
              placeholder="Введите название жанра"
              isRequired={true}
              isError={isError}
              errorItems={errorItems}
              error={error}
            />
            <FormItem htmlFor="categories" top="Тип">
              <ChipsSelect
                name="categories"
                className="vkui-select"
                id="categories"
                placeholder="Типы не выбраны"
                creatable={false}
                value={newCategories || []}
                onChange={setNewCategories}
                options={allCategories}
              />
            </FormItem>
            <FormItem className="py-0 mt-3 mb-2 ml-3 w-32">
              <Checkbox checked={isNewActive} onChange={handleIsNewActive}>
                Активно
              </Checkbox>
            </FormItem>
            <Div>
              <ButtonGroup align="right" stretched>
                {methodType === 'edit' ? (
                  <>
                    <Button
                      className="vkui-edit-button-secondary"
                      mode="secondary"
                      size="m"
                      appearance="accent"
                      onClick={() => setOpenModal('delete')}
                    >
                      Удалить
                    </Button>
                    <Button
                      className="vkui-edit-button-primary"
                      mode="primary"
                      size="m"
                      appearance="accent"
                      onClick={handleSave}
                    >
                      Сохранить
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() =>
                        navigate(`${ADMIN_DIRECTORIES_ROUTE}/genres`)
                      }
                      className="vkui-edit-button-secondary"
                      mode="secondary"
                      size="m"
                      appearance="accent"
                    >
                      Назад
                    </Button>
                    <Button
                      className="vkui-edit-button-primary"
                      mode="primary"
                      size="m"
                      appearance="accent"
                      onClick={handleSave}
                    >
                      Добавить
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </Div>
          </Group>
        </>
      )}
      <ModalRoot activeModal={openModal}>
        <ModalCard id="delete" onClose={() => setOpenModal('')}>
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Удаление жанра
            </Title>
            <Text className="text-color-gray-600">
              Данный жанр будет удален
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                className="vkui-edit-button-primary"
                mode="primary"
                size="m"
                onClick={() => handleDeleteGenre()}
              >
                Удалить
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
      </ModalRoot>
    </>
  );
};

export default GenresDirectoryEdit;
