import React, { ChangeEventHandler, KeyboardEvent } from 'react';
import { Input } from '@vkontakte/vkui';
import { Icon16SearchOutline } from '@vkontakte/icons';

interface IInputSearch {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  type?: string;
  onSubmit: () => void;
}

const InputSearch = ({
  value,
  onChange,
  type = 'text',
  onSubmit,
}: IInputSearch) => {
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };
  return (
    <Input
      onKeyDown={handleKeyDown}
      type={type}
      before={<Icon16SearchOutline fill="#99A2AD" />}
      style={{ width: '200px', height: '42px' }}
      placeholder="Поиск"
      size={15}
      className="input-style bg-color-white"
      value={value}
      onChange={onChange}
      after={null}
    />
  );
};

export default InputSearch;
