import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Div,
  Group,
  ModalCard,
  ModalRoot,
  Placeholder,
  Select,
  Spinner,
  Text,
  Title,
} from '@vkontakte/vkui';
import { Icon24ListDeleteOutline } from '@vkontakte/icons';
import { ITableHeader } from 'lib/types';
import {
  ADMIN_PLACES_ROUTE,
  dataEnricherOptions,
  dataOwnerOptions,
} from 'lib/constants';
import { parseFiltersUrl } from 'lib/utils/parseFunction';
import { ApiResponse } from 'lib/api/api';
import { TextTooltip } from '@vkontakte/vkui/dist/components/TextTooltip/TextTooltip';
import { IPlace } from './types.places';
import {
  placeFilters,
  placeHeaders,
  placeTypeOptions,
} from './constants.places';
import TableSetup from 'components/common/TableSetup';
import useFetch from 'lib/hooks/useFetch';
import useParams from 'lib/hooks/useParams';
import InputSearch from 'components/common/InputSearch';
import FiltersButton from 'components/common/FiltersButton';
import FormItemPanel from 'components/common/FormItemPanel';
import FiltersWrapper from 'components/common/FiltersWrapper';
import PaginationComponent from 'components/common/Pagination';
import CityChipsSelect from 'components/common/CityChipsSelect';

const Places: FC = () => {
  const [activeHeaders, setActiveHeaders] = useState<ITableHeader[]>([]);
  const { data, loading, fetchData } = useFetch<ApiResponse<IPlace[]>>();
  const location = useLocation();
  const [places, setPlaces] = useState<IPlace[]>([]);
  const [openModal, setOpenModal] = useState<string>('');

  const {
    filters,
    setFilters,
    addFiltersAndUpdateUrl,
    reset,
    handleChange,
    openFilters,
    handletoggleFilters,
    setCurrentPage,
    currentPage,
  } = useParams(placeFilters);

  const {
    place_type_ru,
    city_id,
    status,
    description_exists,
    picture_exists,
    source_owner,
    data_enricher,
    changed_from_admin,
  } = filters;

  const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilters({ ...filters, q: value });
  };

  const handleResetFilters = useCallback(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    const queryParams = location.search ? parseFiltersUrl(location.search) : '';

    fetchData({
      path: `/search/places?page=${currentPage - 1}${queryParams && `&${queryParams}`}`,
      method: 'get',
    });
  }, [location.search, currentPage]);

  useEffect(() => {
    if (!loading && data?.results) {
      setPlaces(data.results);
    }
  }, [loading, location.search]);

  useEffect(() => {
    const storedHeaders = localStorage.getItem('columnOrder_places');
    if (storedHeaders) {
      setActiveHeaders(JSON.parse(storedHeaders));
    } else {
      setActiveHeaders(placeHeaders);
    }
  }, []);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <Group>
          <Div className="flex items-center gap-2">
            <InputSearch
              onSubmit={addFiltersAndUpdateUrl}
              value={filters.q as string}
              onChange={handleChangeSearchValue}
            />
            <FiltersButton
              openFilters={openFilters}
              toggle={handletoggleFilters}
            />
            <TableSetup
              tableId="places"
              headers={placeHeaders}
              onActiveHeadersChange={setActiveHeaders}
            />
          </Div>
          {openFilters && (
            <FiltersWrapper
              reset={handleResetFilters}
              addFiltersAndUpdateUrl={addFiltersAndUpdateUrl}
            >
              <FormItemPanel top="Тип площадки" className="p-0">
                <Select
                  allowClearButton
                  className="vkui-select"
                  id="place_type_ru"
                  name="place_type_ru"
                  placeholder="Выберите тип площадки"
                  value={place_type_ru as string}
                  onChange={handleChange}
                  options={placeTypeOptions}
                />
              </FormItemPanel>
              <CityChipsSelect
                city_id={city_id}
                filters={filters}
                setFilters={setFilters}
                reset={handleResetFilters}
                filterKey={'city_id'}
              />
              <FormItemPanel top="Статус" htmlFor="status">
                <Select
                  className="vkui-select"
                  id="status"
                  name="status"
                  placeholder="Выберите статус"
                  value={status as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'true', label: 'Опубликован' },
                    { value: 'false', label: 'Скрыт' },
                  ]}
                />
              </FormItemPanel>
              <FormItemPanel top="Описание" htmlFor="description_exists">
                <Select
                  className="vkui-select"
                  id="description_exists"
                  name="description_exists"
                  placeholder="Наличие описания"
                  value={description_exists as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'true', label: 'Есть' },
                    { value: 'false', label: 'Нет' },
                  ]}
                />
              </FormItemPanel>
              <FormItemPanel top="Изображение" htmlFor="images">
                <Select
                  className="vkui-select"
                  id="picture_exists"
                  name="picture_exists"
                  placeholder="Наличие изображения"
                  value={picture_exists as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'true', label: 'Есть' },
                    { value: 'false', label: 'Нет' },
                  ]}
                />
              </FormItemPanel>
              <FormItemPanel top="Владелец данных" htmlFor="source_owner">
                <Select
                  className="vkui-select"
                  id="source_owner"
                  name="source_owner"
                  placeholder="Выберите владельца"
                  value={source_owner as string}
                  onChange={handleChange}
                  allowClearButton
                  options={dataOwnerOptions}
                />
              </FormItemPanel>
              <FormItemPanel top="Обогащение данных" htmlFor="data_enricher">
                <Select
                  className="vkui-select"
                  id="data_enricher"
                  name="data_enricher"
                  placeholder="Выберите источник"
                  value={data_enricher as string}
                  onChange={handleChange}
                  allowClearButton
                  options={dataEnricherOptions}
                />
              </FormItemPanel>
              <FormItemPanel top="Изменено админом" htmlFor="changed_from_admin">
                <Select
                  className="vkui-select"
                  id="changed_from_admin"
                  name="changed_from_admin"
                  placeholder="Изменено админом"
                  value={changed_from_admin as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'true', label: 'Да' },
                    { value: 'false', label: 'Нет' },
                  ]}
                />
              </FormItemPanel>
            </FiltersWrapper>
          )}
          {places && places.length ? (
            <>
              <div className="custom-scrollbar overflow-auto pb-2">
                <table className="w-full">
                  <thead>
                    <tr className="border-y-1 border-color-steel-gray-80">
                      {activeHeaders.map((header) => (
                        <th
                          key={header.value}
                          className="text-color-gray-600 uppercase py-4 whitespace-nowrap"
                        >
                          <Title
                            className="flex text-sm ml-8 max-w-fit gap-1 items-center cursor-pointer"
                            level="2"
                          >
                            {header.label}
                          </Title>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {places &&
                      places.map(
                        ({
                          id,
                          name,
                          city,
                          status,
                          place_type_ru,
                          description_exists,
                          picture,
                          source_owner,
                          data_enricher,
                        }) => (
                          <tr
                            onClick={() =>
                              window.open(
                                ADMIN_PLACES_ROUTE + `/edit/${id}`,
                                '_blank',
                              )
                            }
                            key={id}
                            className="cursor-pointer transition-colors hover:bg-color-steel-gray-20 h-10"
                          >
                            {activeHeaders.some((h) => h.value === 'id') && (
                              <td className="py-2" id="id">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {id.split('-').pop() || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some((h) => h.value === 'name') && (
                              <td className="py-2" id="name">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {name || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'place_type_ru',
                            ) && (
                              <td className="py-2" id="place_type_ru">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {place_type_ru || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some((h) => h.value === 'city') && (
                              <td className="py-2" id="city">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {city || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'status',
                            ) && (
                              <td className="py-2" id="status">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {status !== null
                                    ? status
                                      ? 'Опубликован'
                                      : 'Скрыт'
                                    : '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'description_exists',
                            ) && (
                              <td className="py-2" id="description_exists">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {description_exists ? 'Есть' : 'Нет'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'picture_exists',
                            ) && (
                              <td className="py-2" id="picture_exists">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {picture ? 'Есть' : 'Нет'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'source_owner',
                            ) && (
                              <td className="py-2" id="source_owner">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {source_owner ? source_owner : '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'data_enricher',
                            ) && (
                              <td className="py-2" id="data_enricher">
                                {data_enricher?.length ? (
                                  <TextTooltip
                                    text={data_enricher.join(', ')}
                                    className="max-w-lg"
                                  >
                                    <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                      {`${data_enricher.join(', ').substring(0, 30)}${data_enricher.join(', ').length > 30 ? '...' : ''}`}
                                    </Text>
                                  </TextTooltip>
                                ) : (
                                  <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                    {'-'}
                                  </Text>
                                )}
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'changed_from_admin',
                            ) && (
                              <td className="py-2" id="changed_from_admin">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {changed_from_admin ? 'Да' : 'Нет'}
                                </Text>
                              </td>
                            )}
                          </tr>
                        ),
                      )}
                  </tbody>
                </table>
              </div>
              {data && data?.count > 10 ? (
                <PaginationComponent
                  count={data?.count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  items={places}
                />
              ) : null}
            </>
          ) : (
            <Div>
              <Placeholder
                icon={<Icon24ListDeleteOutline />}
                header={'Места проведения не найдены'}
              >
                Попробуйте изменить критерии поиска
              </Placeholder>
            </Div>
          )}
        </Group>
      )}
      <ModalRoot activeModal={openModal}>
        <ModalCard id="show" onClose={() => setOpenModal('')}>
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Опубликовать место проведения
            </Title>
            <Text className="text-color-gray-600">
              Все выбранные места проведения будут опубликованы
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                mode="primary"
                className="vkui-edit-button-primary"
                size="m"
                onClick={() => console.log('show')}
              >
                Опубликовать
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
        <ModalCard id="hidden" onClose={() => setOpenModal('')}>
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Скрыть место проведения
            </Title>
            <Text className="text-color-gray-600">
              Все выбранные места проведения будут скрыты
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                mode="primary"
                className="vkui-edit-button-primary"
                size="m"
                onClick={() => console.log('hidden')}
              >
                Скрыть
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
      </ModalRoot>
    </>
  );
};

export default Places;
