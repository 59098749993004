import React, { useCallback, useEffect, useState } from 'react';
import { Div, Placeholder, Spinner, Link } from '@vkontakte/vkui';
import useFetch from 'lib/hooks/useFetch';
import useParamsHook from 'lib/hooks/useParams';
import { useParams } from 'react-router-dom';
import {
  Icon24TicketOutline,
  Icon24ExternalLinkOutline,
} from '@vkontakte/icons';
import { ApiResponse } from 'lib/api/api';
import { parseFiltersUrl } from 'lib/utils/parseFunction';
import { IEventSeance } from './types.events';
import { eventSeancesFilters } from './constants.events';
import { parseDateFrom } from 'lib/utils/date.utils';
import { v4 as uuidv4 } from 'uuid';
import FiltersWrapper from 'components/common/FiltersWrapper';
import PaginationComponent from 'components/common/Pagination';
import CityChipsSelect from 'components/common/CityChipsSelect';
import PlaceChipsSelect from 'components/common/PlaceChipsSelect';
import DateRangePicker from 'components/common/DateRangePicker';

const EventSeances = () => {
  const {
    data: sessionsData,
    loading: sessionsLoading,
    fetchData: fetchSessions,
  } = useFetch<ApiResponse<IEventSeance[]>>();
  const { eventId } = useParams();
  const [sessions, setSessions] = useState<IEventSeance[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { filters, setFilters, addFiltersAndUpdateUrl, reset } =
    useParamsHook(eventSeancesFilters);
  const { city_id, place_id } = filters;
  const [startDatetimeFrom, setStartDatetimeFrom] = useState<Date | undefined>(
    undefined,
  );
  const [startDatetimeTo, setStartDatetimeTo] = useState<Date | undefined>(
    undefined,
  );
  const uuid = uuidv4();

  const fetchSessionsFunction = () => {
    const queryParams = location.search ? parseFiltersUrl(location.search) : '';

    fetchSessions({
      path: `/search/events/seances/${eventId}?page=${
        currentPage - 1
      }${queryParams && `&${queryParams}`}`,
      method: 'get',
    });
  };

  const handleResetFilters = useCallback(() => {
    reset();
    setStartDatetimeTo(undefined);
    setStartDatetimeFrom(undefined);
  }, [reset, setStartDatetimeTo, setStartDatetimeFrom]);

  useEffect(() => {
    fetchSessionsFunction();
  }, [location.search, currentPage]);

  useEffect(() => {
    if (sessionsData?.results && !sessionsLoading) {
      setSessions(sessionsData.results);
    }
  }, [sessionsLoading]);

  return (
    <>
      {sessionsLoading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <>
          <FiltersWrapper
            className="max-w-fit"
            reset={handleResetFilters}
            addFiltersAndUpdateUrl={addFiltersAndUpdateUrl}
          >
            <CityChipsSelect
              city_id={city_id}
              filters={filters}
              setFilters={setFilters}
              reset={handleResetFilters}
              filterKey={'city_id'}
            />
            <PlaceChipsSelect
              place_id={place_id}
              filters={filters}
              setFilters={setFilters}
              reset={handleResetFilters}
              filterKey={'place_id'}
            />
            <DateRangePicker
              title={'Время начала сеансов'}
              withTime={true}
              from={startDatetimeFrom}
              setFrom={setStartDatetimeFrom}
              fromFilterKey={'start_datetime__from'}
              to={startDatetimeTo}
              setTo={setStartDatetimeTo}
              toFilterKey={'start_datetime__to'}
              filters={filters}
              setFilters={setFilters}
            />
          </FiltersWrapper>
          <Div>
            {sessions.length > 0 ? (
              <>
                <table className="w-full table-fixed">
                  <thead className="bg-color-steel-gray-20 h-10 text-color-steel-gray-500 text-sm text-left">
                    <tr>
                      {[
                        'Дата',
                        'Город',
                        'Место проведения',
                        'Сеанс',
                        'Продолжительность',
                        'Форматы',
                        'Кол-во билетов',
                        'Ссылка на виджет',
                      ].map((head) => {
                        return (
                          <th className="font-normal pl-4" key={head}>
                            {head}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {sessions.map(
                      ({
                        id,
                        place_id,
                        place_name,
                        city,
                        start,
                        duration_minutes,
                        formats,
                        tickets_count,
                        widget_url,
                      }) => {
                        return (
                          <tr key={id} className="text-sm">
                            <td className="h-10 pl-4">
                              {parseDateFrom({
                                date: start,
                                format: 'day',
                                utc: false,
                              })}
                            </td>
                            <td className="h-10 pl-4">{city}</td>
                            <td className="h-10 pl-4">
                              <a
                                target="_blank"
                                rel="noreferrer"
                                className="text-[#2D81E0] hover:underline"
                                href={`/places/edit/${place_id}`}
                              >
                                {place_name}
                              </a>
                            </td>
                            <td className="h-10 pl-4">
                              {parseDateFrom({
                                date: start,
                                format: 'time',
                                utc: false,
                              })}
                            </td>
                            <td className="h-10 pl-4">
                              {duration_minutes
                                ? `${duration_minutes} мин`
                                : '-'}
                            </td>
                            <td className="h-10 pl-4">
                              {formats.length > 0
                                ? formats
                                    .map((format) => format.title)
                                    .join(', ')
                                : '-'}
                            </td>
                            <td className="h-10 pl-4">
                              {tickets_count || '-'}
                            </td>
                            <td className="h-10 pl-4">
                              {widget_url ? (
                                <Link
                                  href={`${widget_url}?interaction_id=${uuid}`}
                                  target="_blank"
                                >
                                  <Icon24ExternalLinkOutline
                                    width={18}
                                    height={18}
                                    className="mb-1"
                                  />
                                </Link>
                              ) : (
                                '-'
                              )}
                            </td>
                          </tr>
                        );
                      },
                    )}
                  </tbody>
                </table>
                {sessionsData && sessionsData?.count > 10 && (
                  <PaginationComponent
                    count={sessionsData?.count}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    items={sessions}
                  />
                )}
              </>
            ) : (
              <Div>
                <Placeholder
                  icon={<Icon24TicketOutline />}
                  header={'Сеансы не найдены'}
                >
                  Выберите другое мероприятия или измените критерии поиска
                </Placeholder>
              </Div>
            )}
          </Div>
        </>
      )}
    </>
  );
};

export default EventSeances;
