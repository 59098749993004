import React from 'react';
import { Button, Div } from '@vkontakte/vkui';
import { type ReactNode } from 'react';

interface IFiltersWrapper {
  children: ReactNode;
  reset: () => void;
  addFiltersAndUpdateUrl: () => void;
  className?: string;
}

const FiltersWrapper = ({
  children,
  reset,
  addFiltersAndUpdateUrl,
  className,
}: IFiltersWrapper) => {
  return (
    <Div
      className={`pt-1 flex-wrap flex items-end gap-2 max-w-6xl ${className || ''}`}
    >
      {children}
      <div>
        <Button
          className="vkui-edit-button-primary min-w-fit mt-6"
          size="m"
          onClick={addFiltersAndUpdateUrl}
        >
          Применить
        </Button>
        <Button
          mode="link"
          className="vkui-edit-button-tertiary min-w-fit ml-2"
          size="m"
          onClick={reset}
        >
          Сбросить фильтры
        </Button>
      </div>
    </Div>
  );
};

export default FiltersWrapper;
