import React, { useEffect, useRef, useState, FC } from 'react';
import FormItemPanel from './FormItemPanel';
import { EmptyChipsSelect } from './EmptyChipsSelect';
import ChipsSelectComponent from './ChipsSelectComponent';
import { IChip, ICity } from 'lib/types';
import useDebounce from 'lib/hooks/useDebounce';
import useFetch from 'lib/hooks/useFetch';
import { ApiResponse } from 'lib/api/api';
import useInfiniteScroll from 'react-infinite-scroll-hook';

interface ICityChipsSelectProps {
  // eslint-disable-next-line
  city_id: any;
  // eslint-disable-next-line
  filters: Record<string, any>;
  // eslint-disable-next-line
  setFilters: (filters: Record<string, any>) => void;
  reset: () => void;
  filterKey: string;
}

const CityChipsSelect: FC<ICityChipsSelectProps> = ({
  city_id,
  filters,
  setFilters,
  reset,
  filterKey,
}) => {
  const [forceUpdate, setForceUpdate] = useState(0);
  const [allCities, setAllCities] = useState<ICity[]>([]);
  const [currentCities, setCurrentCities] = useState<ICity[]>([]);
  const [citiesCurrentPage, setCitiesCurrentPage] = useState<number>(1);
  const [citiesSearch, setCitiesSearch] = useState<string>('');
  const debouncedCitySearch = useDebounce(citiesSearch, 500);
  const cityListInnerRef = useRef<HTMLDivElement>(null);

  const rerender = () => {
    setForceUpdate((prevState) => prevState + 1);
  };

  const {
    data: citiesData,
    loading: citiesLoading,
    fetchData: fetchCities,
  } = useFetch<ApiResponse<ICity[]>>();

  const [citiesSentryRef, { rootRef: citiesRootRef }] = useInfiniteScroll({
    onLoadMore: () => {
      setCitiesCurrentPage((prev) => prev + 1);
    },
    hasNextPage: !!citiesData?.next,
    loading: citiesLoading,
    rootMargin: '0px 0px 0px 0px',
    delayInMs: 200,
  });

  useEffect(() => {
    handleReset();
  }, [reset]);
  
  const handleReset = () => {
    setCitiesSearch('');
  };

  useEffect(() => {
    fetchCities({
      path: `/geoposition/?page=${
        citiesCurrentPage - 1
      }&page_size=30${citiesSearch && `&q=${debouncedCitySearch}`}`,
      method: 'get',
    });
  }, [citiesCurrentPage, debouncedCitySearch]);

  useEffect(() => {
    setCitiesCurrentPage(1);
    setCurrentCities([]);
  }, [citiesSearch]);

  useEffect(() => {
    if (!citiesLoading && citiesData?.results) {
      setCurrentCities(citiesData.results);
      setAllCities((prev) => [...prev, ...citiesData.results]);

      cityListInnerRef.current?.scrollIntoView({
        inline: 'start',
        block: 'nearest',
      });
    }
  }, [citiesLoading]);

  const { data, loading, fetchData } = useFetch<ICity>();
  const [checkedCities, setCheckedCities] = useState<IChip[]>([]);

  const fetchDetail = async (id: string) => {
    await fetchData({
      path: `/geoposition/${id}`,
      method: 'get',
    });
  };

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const getCityNameById = async (ids: IChip[]) => {
    if (ids?.length > 0) {
      for (const id of ids) {
        await fetchDetail(id.value);
        await delay(100);
      }
    }
  };

  useEffect(() => {
    if (!loading && data) {
      setCheckedCities((prev) => {
        const updatedCities = [
          ...prev,
          {
            label: data.name,
            value: data.id,
          },
        ];

        const uniqueCities = Array.from(
          new Map(updatedCities.map((item) => [item.value, item])).values(),
        );

        return uniqueCities;
      });

      rerender();
    }
  }, [data, loading]);

  useEffect(() => {
    getCityNameById(city_id);
  }, []);

  return (
    <>
      <FormItemPanel top="Город">
        {allCities.length === 0 ? (
          <EmptyChipsSelect />
        ) : (
          <ChipsSelectComponent
          key={forceUpdate}
            disabled={allCities.length === 0}
            hasNextPage={!!citiesData?.next}
            loaderRef={citiesSentryRef}
            listRef={citiesRootRef}
            listInnerRef={cityListInnerRef}
            onInput={(e) => {
              setCitiesSearch(e.target.value.trim());
            }}
            inputValue={citiesSearch}
            chipClassName="max-w-[175px] truncate"
            placeholder="Выберите город"
            values={checkedCities}
            onChange={(value) => setFilters({ ...filters, [filterKey]: value })}
            options={currentCities.map((city) => ({
              label: city.name,
              value: city.id,
            }))}
          />
        )}
      </FormItemPanel>
    </>
  );
};

export default CityChipsSelect;
