export const reviewEventHeaders = [
  {
    order: 1,
    label: 'ID Пользователя',
    value: 'user_id',
  },
  {
    order: 2,
    label: 'Анонимно',
    value: 'is_anonim',
  },
  {
    order: 3,
    label: 'Мероприятие',
    value: 'event_name',
  },
  {
    order: 4,
    label: 'Оценка',
    value: 'value',
  },
  {
    order: 5,
    label: 'Отзыв',
    value: 'text',
  },
  {
    order: 6,
    label: 'Статус',
    value: 'status',
  },
  {
    order: 7,
    label: 'Дата и время отзыва',
    value: 'created_at',
  },
  {
    order: 8,
    label: 'ID Мероприятия',
    value: 'event_id',
  },
];

export const reviewEventFilters = {
  q: '',
  ordering: '',
  user_id: '',
  event_name: '',
  event_id: '',
  status: '',
  is_text: '',
  is_complaint: '',
  is_anonim: '',
};

export const reviewEventBlank = {
  id: '',
  user_id: '',
  is_anonim: false,
  status: '',
  value: 0,
  text: '',
  created_at: '',
  event_id: '',
  event_name: '',
  profile: {
    first_name: '',
    last_name: '',
    phone: '',
  },
  complaints: [],
};

export const reviewStatusMap = {
  PENDING: 'На проверке',
  PUBLISHED: 'Опубликован',
  REJECTED: 'Скрыт',
  DELETED: 'Удален',
};
